import React, {Component} from 'react';
import TranspCA from "./section_select/transp_c_a";
import TranspCM from "./section_select/transp_c_m";
import TransC from "./section_select/trans_c";
import AgendAdua from "./section_select/agend_adua";
import BodZona from "./section_select/bod_zona";
import AlmPuert from "./section_select/alm_puert";
import RentEquip from "./section_select/rent_equip";
import Fabric from "./section_select/fabric";

class AgendAduaSection extends Component {
    render() {
        return (
            <>
                <section className="py-0" id="Servicios">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6 px-0 order-lg-2">
                                <div className="sticky-top h-lg-100vh py-9">
                                    <div className="bg-holder aduanabackground"
                                         data-zanim-trigger="scroll"
                                         data-zanim-lg='{"animation":"zoom-out-slide-left","delay":0.4}'>
                                        <div className="row justify-content-center text-center pt-0">
                                            <div className="col-lg-9 px-4">
                                                <h3 className="fs-2 fs-sm-3 text-white pt-5">Agenciamiento aduanero</h3>
                                                <p className="lead text-justify text-white px-lg-7">Agencia de aduanas nivel 1, con los mejores profesionales a su disposición le prestarán servicio de asesoría para sus cargas especiales, clasificación arancelaria, descargues directos,
                                                    inspección y pre-inspección de mercancías, permisos y vistos buenos ante las diferentes entidades; tanto para importaciones como para exportaciones.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 bg-white py-2">
                                <div className="row h-100 align-items justify-content-center">
                                    <div className="py-5 col-sm-11 col-md-8 col-lg-11 col-xl-9 px-xl-5">

                                        <TranspCA active={false}/>
                                        <TranspCM active={false}/>
                                        <TransC active={false}/>
                                        <AgendAdua active={true}/>
                                        <BodZona active={false}/>
                                        <AlmPuert active={false}/>
                                        <RentEquip active={false}/>
                                        <Fabric active={false}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default AgendAduaSection;