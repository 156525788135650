import React, {Component} from 'react';
import Navigation from "../navigation";
import SectionTop from "./services_top";
import TranspCargaSection from "./transp_c_section";
import Contact from "../home/contact";
import Footer from "../footer";
import LoadScriptTheme from "../load_scripts";

class TranspC extends Component {
    render() {
        return (
            <>
                <Navigation/>
                <SectionTop/>
                <TranspCargaSection/>
                <Contact/>
                <Footer/>
                <LoadScriptTheme/>
            </>
        );
    }
}

export default TranspC;