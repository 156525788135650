import React, {Component} from 'react';
import Navigation from "../navigation";
import Contact from "../home/contact";
import Footer from "../footer";
import LoadScriptTheme from "../load_scripts";
import ContactTop from "./contact_top";

class ContactPage extends Component {
    render() {
        return (
            <>
                <Navigation/>
                <ContactTop/>
                <Contact/>
                <Footer/>
                <LoadScriptTheme/>
            </>
        );
    }
}

export default ContactPage;