
import './App.css';
import React from 'react'
import {BrowserRouter as Router, Route} from 'react-router-dom'
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import ImgLoad from './components/assets/img/taladro-load.gif'
import Home from './components/home/home'
import We from './components/we/we'
import TranspCargaAereo from './components/services/Transp_carga_aereo'
import TranspCargamaritimo from './components/services/transp_carga_maritimo'
import TranspCarga from './components/services/transp_c'
import AgendAdua from "./components/services/agend_adua";
import BodZona from "./components/services/bod_zona";
import AlmPuert from "./components/services/alm_puert";
import RentEquip from "./components/services/rent_equip";
import Fabric from "./components/services/fabric";
import DrillingEquipment from "./components/products/drilling_equipment";
import MudPumpsPage from "./components/products/section_select/mud_pumps_page";
import WellControlPage from "./components/products/well_control_page";
import HandlingToolsPage from "./components/products/handling_tools_page";
import MudSystemPage from "./components/products/mud_system_page";
import DownholeToolsPage from "./components/products/downhole_tools_page";
import DrillString from "./components/products/drill_string";
import ValvesPage from "./components/products/valves_page";
import ContactPage from "./components/contact/contact_page";
function App() {
  return (
    <Router>
      <main className="main minh-100vh">
          <div className="preloader" id="preloader">
              <div className="loader">
                  <img src={ImgLoad} alt="loading"/>
              </div>
          </div>
          <Route exact path="/" component={Home} />
          <Route exact path="/We" component={We} />
          <Route exact path="/contact" component={ContactPage} />
          <Route exact path="/Transporte-de-carga-aereo" component={TranspCargaAereo} />
          <Route exact path="/Transporte-de-carga-maritimo" component={TranspCargamaritimo} />
          <Route exact path="/Transporte-nacional-de-carga" component={TranspCarga} />
          <Route exact path="/Agenciamiento-aduanero" component={AgendAdua} />
          <Route exact path="/Bodegaje-en-zona-franca" component={BodZona} />
          <Route exact path="/Almacenamiento-en-puerto" component={AlmPuert} />
          <Route exact path="/Renta-de-equipos" component={RentEquip} />
          <Route exact path="/Fabricaciones" component={Fabric} />
          <Route exact path="/Drilling-equipment" component={DrillingEquipment} />
          <Route exact path="/Mud-pumps" component={MudPumpsPage} />
          <Route exact path="/Well-control-equipment" component={WellControlPage} />
          <Route exact path="/Handling-tools" component={HandlingToolsPage} />
          <Route exact path="/Mud-system-and-solid-control-equipment" component={MudSystemPage} />
          <Route exact path="/Downhole-tools" component={DownholeToolsPage} />
          <Route exact path="/Drill-string-and-tubulars" component={DrillString} />
          <Route exact path="/Valves" component={ValvesPage} />

      </main>
        <NotificationContainer/>
    </Router>
  );
}

export default App;
