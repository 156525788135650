import React, {Component} from 'react';
import Plataformapetrolera from "../../assets/img/icons/plataforma-petrolifera.svg";
import {Link} from "react-router-dom";

class AlmPuert extends Component {
    render() {
        return (
            <Link to="/Almacenamiento-en-puerto">
                <div className="media mb-2 card-service" data-zanim-timeline="{}"
                     data-zanim-trigger="scroll">
                    <div className="oveflow-hidden">
                        <img className="mr-3 mr-sm-4"
                             src={Plataformapetrolera} alt=""
                             width="50"
                             data-zanim-xs='{"delay":0.2,"animation":"slide-right"}'/>
                    </div>
                    <div className="media-body pt-2">
                        <div className="overflow-hidden">
                            <h4 className={this.props.active?('mb-2 text-info'):('mb-2 text-primary')}
                                data-zanim-xs='{"delay":0.1,"animation":"slide-right"}'>Almacenamiento
                                en puerto</h4>
                        </div>
                    </div>
                </div>
            </Link>
        );
    }
}

export default AlmPuert;