import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import LogoMenu from './assets/img/logo-menu-2.png'
class Navigation extends Component {
    render() {
        return (

            <nav className="navbar navbar-expand-lg navbar-dark fixed-top navbar-sparrow">
                <div className="container">
                    <Link className="navbar-brand" to="/">
                        <img src={LogoMenu} alt="dyssoluciones" height="55"/>
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarNavDropdown1" aria-controls="navbarNavDropdown1" aria-expanded="false"
                            aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown1">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item dropdown">
                                <Link className="nav-link" id="navbarDropdownMenuLink1"
                                      to="/">Inicio</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" id="navbarDropdownMenuLink1" href="#"
                                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Alianzas</a>
                                <div className="dropdown-menu dropdown-menu-right"
                                     aria-labelledby="navbarDropdownMenuLink1">
                                    <a className="dropdown-item" href="https://globalstsas.com" target="_blank">GLOBAL
                                        S.A.S</a>
                                    <a className="dropdown-item" href="http://gmh.platinoweb.com" target="_blank">GMH
                                        COLOMBIA S.A.S</a>
                                </div>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/We">Empresa</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" id="navbarDropdownMenuLink1" href="#"
                                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Servicios</a>
                                <div className="dropdown-menu dropdown-menu-right"
                                     aria-labelledby="navbarDropdownMenuLink1">
                                    <Link className="dropdown-item" to="/Transporte-de-carga-aereo">Transporte
                                        de carga aéreo</Link>
                                    <Link className="dropdown-item" to="/Transporte-de-carga-maritimo">Transporte
                                        de carga marítimo</Link>
                                    <Link className="dropdown-item" to="/Transporte-nacional-de-carga">Transporte
                                        nacional de carga</Link>
                                    <Link className="dropdown-item" to="/Agenciamiento-aduanero">Agenciamiento
                                        aduanero</Link>
                                    <Link className="dropdown-item" to="/Bodegaje-en-zona-franca">Bodegaje
                                        en Zona Franca</Link>
                                    <Link className="dropdown-item" to="/Almacenamiento-en-puerto">Almacenamiento
                                        en puerto</Link>
                                    <Link className="dropdown-item" to="/Renta-de-equipos">Renta de
                                        Equipos</Link>
                                    <Link className="dropdown-item" to="/Fabricaciones">Fabricaciones</Link>
                                </div>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" id="navbarDropdownMenuLink1" href="#"
                                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Productos</a>
                                <div className="dropdown-menu dropdown-menu-right"
                                     aria-labelledby="navbarDropdownMenuLink1">
                                    <Link className="dropdown-item" to="/Drilling-equipment">Drilling
                                        Equipment</Link>
                                    <Link className="dropdown-item" to="/Mud-pumps">Mud Pumps</Link>
                                    <Link className="dropdown-item" to="/Well-control-equipment">Well
                                        Control Equipment</Link>
                                    <Link className="dropdown-item" to="/Handling-tools">Handling
                                        Tools</Link>
                                    <Link className="dropdown-item"
                                          to="/Mud-system-and-solid-control-equipment">Mud System & Solid
                                        Control Equipment</Link>
                                    <Link className="dropdown-item" to="/Downhole-tools">Downhole
                                        Tools</Link>
                                    <Link className="dropdown-item" to="/Drill-string-and-tubulars">Drill
                                        String & Tubulars</Link>
                                    <Link className="dropdown-item" to="/Valves">Valves</Link>
                                </div>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/contact">Contacto</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}

export default Navigation;