import React, {Component} from 'react';
import TranspCA from "./section_select/transp_c_a";
import TranspCM from "./section_select/transp_c_m";
import TransC from "./section_select/trans_c";
import AgendAdua from "./section_select/agend_adua";
import BodZona from "./section_select/bod_zona";
import AlmPuert from "./section_select/alm_puert";
import RentEquip from "./section_select/rent_equip";
import Fabric from "./section_select/fabric";

class RentEquipSection extends Component {
    render() {
        return (

            <>
                <section className="py-0" id="Servicios">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6 px-0 order-lg-2">
                                <div className="sticky-top h-lg-100vh py-9">

                                    <div className="bg-holder" data-zanim-trigger="scroll"
                                         data-zanim-lg='{"animation":"zoom-out-slide-left","delay":0.4}'>
                                        <iframe title="renta de equipos" className="bg-holder" src="Catalogos/R/mobile/index.html" frameBorder="0"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 bg-white py-2">
                                <div className="row h-100 align-items justify-content-center">
                                    <div className="py-5 col-sm-11 col-md-8 col-lg-11 col-xl-9 px-xl-5">
                                        <TranspCA active={false}/>
                                        <TranspCM active={false}/>
                                        <TransC active={false}/>
                                        <AgendAdua active={false}/>
                                        <BodZona active={false}/>
                                        <AlmPuert active={false}/>
                                        <RentEquip active={true}/>
                                        <Fabric active={false}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default RentEquipSection;