import React, {Component} from 'react';
import axios from 'axios'
import Contact1 from './../assets/img/contac1.webp'
import Contact2 from './../assets/img/contac2.webp'
import Contact3 from './../assets/img/contac3.webp'
import Cookies from 'universal-cookie';
import LogoMenu2 from "../assets/img/logo-menu-1.png";
import {NotificationManager} from 'react-notifications';
const cookies = new Cookies();
class Contact extends Component {
    state = {
        formName: '',
        formCel: '',
        formEmail: '',
        formMessage: '',
        messagesend:false,
        company:[]
    }
    componentDidMount = async e=> {
        const res = await axios.get('https://tallboss.com/api/get_data_page_client/1624984401011');
        this.setState({company : res.data});
        var datoclient = cookies.get('countwebclient');
        if( datoclient!== 'true'){
            await axios.post('https://tallboss.com/api/set_visit_page_client', {
                id_empresa_compania_time: '1624984401011'
            })
            cookies.set('countwebclient', 'true', { path: '/' , maxAge : 1800});
        }
    }
    onSubmit = async e =>{
        e.preventDefault();
        if(this.state.formName===""){
            NotificationManager.warning('Atencion', 'Por favor completa el nombre', 3000);
        }else if(this.state.formCel===""){
            NotificationManager.warning('Atencion', 'Por favor escribe un número de teléfono', 3000);
        }else if(this.state.formEmail===""){
            NotificationManager.warning('Atencion', 'Por favor escribe un correo', 3000);
        }else if(this.state.formMessage===""){
            NotificationManager.warning('Atencion', 'Por favor escribe un mensaje', 3000);
        }else{
           
            await axios.post('https://tallboss.com/api/set_message_page_client',{
                formName: this.state.formName,
                formCel: this.state.formCel,
                formEmail: this.state.formEmail,
                formMessage: this.state.formMessage,
                id_empresa_compania_time: '1624984401011'
            })
            this.setState({
                formName: '',
                formCel: '',
                formEmail: '',
                formMessage: '',
                messagesend:true
            })
            NotificationManager.success('Correcto', 'Mensaje enviado correctamente', 3000);
        }
    }
    onChangeFormName = (e)=>{
        this.setState({
            formName: e.target.value
        })
    }
    onChangeFormCel = (e) =>{
        this.setState({
            formCel: e.target.value
        })
    }
    onChangeFormEmail = (e) =>{
        this.setState({
            formEmail: e.target.value
        })
    }
    onChangeFormMessage = (e) =>{
        this.setState({
            formMessage: e.target.value
        })
    }
    render() {
        return (
            <>
                <div className="container-fluid pt-3 bg-300">
                    <div className="row justify-content-center" data-zanim-timeline="{}" data-zanim-trigger="scroll">
                        <div className="col-md-10 col-lg-7 col-xl-6 text-center">
                            <p className="text-900">Puedes conectar con nosotros o enviarnos un mensaje, con gusto los atenderemos.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 order-1 order-lg-0 pr-lg-4">
                            <form className="zform" onSubmit={this.onSubmit}>
                                <input className="form-control border-300" type="text" placeholder="Tu nombre" value={this.state.formName} onChange={this.onChangeFormName} required="required" />
                                <div className="row">
                                    <div className="col-md-6">
                                        <input className="form-control border-300 mt-3" type="text" placeholder="Tu numero de telefono" value={this.state.formCel} onChange={this.onChangeFormCel} required="required" />
                                    </div>
                                    <div className="col-md-6">
                                        <input className="form-control border-300 mt-3" type="text" placeholder="Tu correo" value={this.state.formEmail}
                                               onChange={this.onChangeFormEmail} required="required" />
                                    </div>
                                </div>
                                <textarea className="form-control border-300 mt-3" rows="5" placeholder="Tu mensaje"
                                          value={this.state.formMessage} onChange={this.onChangeFormMessage} required="required"/>
                                {this.state.messagesend ?(
                                    <div className="alert alert-success m-2" role="alert">
                                        ✔ Su mensaje ha sido enviado exitosamente, gracias por confiar en saludmedicos
                                    </div>
                                ) : (
                                    <button className="btn btn-block btn-primary mt-4" type="submit"><span className="text-uppercase">Enviar mensaje</span>
                                        <span className="fas fa-paper-plane text-white ml-1"/></button>
                                )}
                                <div className="zform-feedback mt-4"/>
                            </form>
                            <div className="text-center py-3"><span className="fs-2">Eres el visitante numero:</span><br/>
                                <div className="card mx-lg-6">
                                    {this.state.company.map(companyund =>
                                        <span key={companyund.id} className="font-weight-extra-bold text-danger fs-3">{companyund.count}</span>
                                    )}

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-7 pl-lg-4">
                            <div className="owl-carousel owl-theme owl-dots-inner owl-theme-white" data-options='{"items":1,"autoplay":true,"loop":true,"autoplayHoverPause":true,"animateOut":"fadeOut","nav":false}'>
                                <img className="h-100 rounded fit-cover" src={Contact2} alt="Medico paciente mano camilla"/>
                                <img className="h-100 rounded fit-cover" src={Contact1} alt="Enfermera via suero"/>
                                <img className="h-100 rounded fit-cover" src={Contact3} alt="Doctor portatil videollamada"/>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="text-sans-serif py-3" id="digital-socials">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 pr-lg-5">
                                <img src={LogoMenu2} alt="D&S" className="img-fluid"/>
                                <p>Proveer a la industria petrolera con cualquier clase de equipos, partes y repuestos
                                    de manera urgente y efectiva. Para nosotros es un placer ofrecerles nuestros
                                    productos, servicios y asesoría, brindándoles el soporte y la asistencia en las
                                    diferentes marcas, ajustándonos a sus necesidades.</p>
                            </div>
                            <div className="col-md-6 col-lg-6 mt-6 mt-lg-0">
                                <h4 className="mb-3">D&S SOLUCIONES EMPRESARIALES S.A.S.</h4>
                                <p className="mb-0"><span className="text-black">Envienos un email a:</span>
                                    <br/><a className="text-700">comercial1@dysglobal.com.co</a>
                                </p>
                                <h4 className="mb-3 mt-4">Estamos situados en</h4>
                                <address>Mosquera, Cundinamarca, Colombia
                                    <br/>Carrera 3 NO. 4-9 PISO 2
                                    <br/>CELULAR
                                    <br/>+57 320 3446898
                                    <br/>+57 317 4418303
                                </address>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default Contact;