import React, {Component} from 'react';
import Navigation from "../navigation";
import SectionTop from "./services_top";
import Contact from "../home/contact";
import Footer from "../footer";
import LoadScriptTheme from "../load_scripts";
import BodZonaSection from "./bod_zona_section";

class BodZona extends Component {
    render() {
        return (
            <>
                <Navigation/>
                <SectionTop/>
                <BodZonaSection/>
                <Contact/>
                <Footer/>
                <LoadScriptTheme/>
            </>
        );
    }
}

export default BodZona;