import React, {Component} from 'react';
import Navigation from "../navigation";
import ServicesTop from "../services/services_top";
import DrillingEquip from "./section_select/drilling_equip";
import MudPumps from "./section_select/mud_pumps";
import WellControlEquip from "./section_select/well_control_equip";
import HandlingTools from "./section_select/handling_tools";
import MudSystem from "./section_select/mud_system";
import DownholeTools from "./section_select/downhole_tools";
import Valves from "./section_select/valves";
import DrillString_s from "./section_select/drill_string";
import Contact from "../home/contact";
import Footer from "../footer";
import LoadScriptTheme from "../load_scripts";

class DrillString extends Component {
    render() {
        return (
            <>
                <Navigation/>
                <ServicesTop/>
                <section className="py-0" id="Servicios">

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6 px-0 order-lg-2">
                                <div className="sticky-top h-lg-100vh py-9">
                                    <div className="bg-holder" data-zanim-trigger="scroll"
                                         data-zanim-lg='{"animation":"zoom-out-slide-left","delay":0.4}'>
                                        <iframe title="Drill Strings & Tubulars" className="bg-holder" src="../Catalogos/DST/mobile/index.html" frameBorder="0"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 bg-white py-2">
                                <div className="row h-100 align-items justify-content-center">
                                    <div className="py-2 col-sm-11 col-md-8 col-lg-11 col-xl-9 px-xl-5">
                                        <div className="mb-4 mb-lg-4 oveflow-hidden">
                                            <h1 className="text-info fs-2 fs-md-3" data-zanim-xs='{"delay":0}'
                                                data-zanim-trigger="scroll">Drill Strings & Tubulars</h1>
                                            <h2 className="text-underline text-primary fs-2 fs-md-3"
                                                data-zanim-xs='{"delay":0}' data-zanim-trigger="scroll">Cuerdas de perforación y tubulares</h2>
                                        </div>
                                        <DrillingEquip active={false}/>
                                        <MudPumps active={false}/>
                                        <WellControlEquip active={false}/>
                                        <HandlingTools active={false}/>
                                        <MudSystem active={false}/>
                                        <DownholeTools active={false}/>
                                        <DrillString_s active={true}/>
                                        <Valves active={false}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Contact/>
                <Footer/>
                <LoadScriptTheme/>
            </>
        );
    }
}

export default DrillString;