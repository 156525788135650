import React, {Component} from 'react';

class Footer extends Component {
    render() {
        return (
            <footer className="footer bg-primary text-600 py-3 text-sans-serif text-center overflow-hidden"
                    data-zanim-timeline="{}" data-zanim-trigger="scroll">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 order-lg-2">
                            <a className="indicator indicator-up" href="#top"><span
                                className="indicator-arrow indicator-arrow-one"
                                data-zanim-xs='{"from":{"opacity":0,"y":15},"to":{"opacity":1,"y":-5,"scale":1},"ease":"Back.easeOut","duration":0.4,"delay":0.9}'/><span
                                className="indicator-arrow indicator-arrow-two"
                                data-zanim-xs='{"from":{"opacity":0,"y":15},"to":{"opacity":1,"y":-5,"scale":1},"ease":"Back.easeOut","duration":0.4,"delay":1.05}'/></a>
                        </div>
                        <div className="col-lg-4 text-lg-left mt-4 mt-lg-0">
                            <p className="fs-0 text-white ls font-weight-bold mb-0">Copyright © 2021
                                DYSSOLUCIONES</p>
                        </div>
                        <div className="col-lg-4 text-lg-right order-lg-2 mt-2 mt-lg-0">
                            <p className="fs-0 text-white ls font-weight-bold mb-0">Community manager, Marketing,
                                Made by <svg
                                    className="svg-inline--fa fa-check-circle fa-w-16 text-danger fs-1 mx-1"
                                    aria-hidden="true" data-prefix="far" data-icon="check-circle" role="img"
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                    <path fill="currentColor"
                                          d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z"/>
                                </svg><a
                                    className="text-white" target="_blank"
                                    href="https://engineeringservices.co/">ENGINEERING &amp; SERVICES</a></p>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;