import React, {Component} from 'react';
import Navigation from "../navigation";
import LoadScriptTheme from "../load_scripts";
import Footer from "../footer";
import Contact from "../home/contact";
import We_c from '../home/we'
import SectionTop from "./section_top";

class We extends Component {
    render() {
        return (
            <>
                <Navigation/>
                <SectionTop/>
                <We_c/>
                <Contact/>
                <Footer/>
            <LoadScriptTheme/>
            </>
        );
    }
}

export default We;