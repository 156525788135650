import React, {Component} from 'react';
import {Link} from "react-router-dom";
import V_img from "../../assets/img/V.webp";

class Valves extends Component {
    render() {
        return (
            <Link to="/Valves">
                <div className="media mb-2 card-service" data-zanim-timeline="{}"
                     data-zanim-trigger="scroll">

                    <div className="oveflow-hidden">
                        <img className="mr-3 mr-sm-4" src={V_img}
                             alt="" width="50"
                             data-zanim-xs='{"delay":0.2,"animation":"slide-right"}'/>
                    </div>
                    <div className="media-body pt-2">
                        <div className="overflow-hidden">
                            <h4 className={this.props.active?('mb-2 text-info'):('mb-2 text-primary')}
                                data-zanim-xs='{"delay":0.1,"animation":"slide-right"}'>Valves</h4>
                        </div>
                    </div>
                </div>
            </Link>
        );
    }
}

export default Valves;