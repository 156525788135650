import React, {Component} from 'react';
import LoadScriptTheme from '../load_scripts.js'
import Navigation from "../navigation";
import De_Img from '../assets/img/DE.webp'
import Wce_Img from '../assets/img/WCE.webp'
import Ht_Img from '../assets/img/HT.webp'
import Mp_Img from '../assets/img/MP.webp'
import Mssce_Img from '../assets/img/MSSCE.webp'
import Dt_Img from '../assets/img/DT.webp'
import Dst_Img from '../assets/img/DST.webp'
import V_Img from '../assets/img/V.webp'
import {Link} from 'react-router-dom'
import We from './we'
import Contact from "./contact";
import Footer from "../footer";
class Home extends Component {
    render() {
        return (
            <div>
                <Navigation/>
                <section className="py-0 overflow-hidden" id="digital-header">
                    <div className="bg-holder overlay digital-header overlay HomeBackground"
                         data-zanim-xs='{"delay":0,"animation":"zoom-out"}' data-zanim-trigger="scroll">
                    </div>
                    <div className="container">
                        <div className="row minh-100vh align-items-center pb-8 justify-content-center text-center">
                            <div className="col-lg-8 col-xl-7" data-zanim-timeline="{}" data-zanim-trigger="scroll">
                                <div className="parallax pt-10" data-rellax-speed="2">
                                    <div data-zanim-xs='{"delay":0.5}'>
                                        <Link className="btn btn-primary rounded-capsule" to="/contact">Contáctenos</Link>
                                    </div>
                                </div>
                                <div className="parallax pt-3" data-rellax-speed="2">
                                    <div data-zanim-xs='{"delay":0.5}'>
                                        <a className="btn btn-success rounded-capsule text-white"
                                           href="https://api.whatsapp.com/send?phone=573203446898&text=Hola%2C%20deseo%20adquirir%20m%C3%A1s%20informaci%C3%B3n"
                                           target="_blank" data-zanim-xs='{"delay":0.4}'><span className="fab fa-whatsapp"/> + 57 320 3446898
                                        </a>
                                    </div>
                                </div>
                                <div className="parallax pt-3" data-rellax-speed="2">
                                    <div data-zanim-xs='{"delay":0.5}'>
                                        <a className="btn btn-success rounded-capsule text-white"
                                           href="https://api.whatsapp.com/send?phone=573174418303&text=Hola%2C%20deseo%20adquirir%20m%C3%A1s%20informaci%C3%B3n"
                                           target="_blank" data-zanim-xs='{"delay":0.4}'><span className="fab fa-whatsapp"/> + 57 317 4418303
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a className="indicator indicator-down" data-zanim-timeline='{"delay":1}'
                           data-zanim-trigger="scroll" href="#target-down" data-offset="78"><span
                            className="indicator-arrow indicator-arrow-one"
                            data-zanim-xs='{"from":{"opacity":0,"y":15},"to":{"opacity":1,"y":-5,"scale":1},"ease":"Back.easeOut","duration":0.4,"delay":0.25}'/><span
                            className="indicator-arrow indicator-arrow-two"
                            data-zanim-xs='{"from":{"opacity":0,"y":15},"to":{"opacity":1,"y":-5,"scale":1},"ease":"Back.easeOut","duration":0.4,"delay":0.5}'/></a>
                    </div>
                </section>
                <div id="target-down"/>
                <section className="py-4 bg-primary">
                    <div className="container-fluid">
                        <div className="row m-0">
                            <div className="col-sm-6 col-lg-3 text-center">
                                <Link className="d-block" to="/Drilling-equipment">
                                    <img className="w-100 rounded" src={De_Img} alt=""
                                         data-zanim-xs='{"delay":0,"animation":"zoom-in"}' data-zanim-trigger="scroll"/>
                                    <h4 className="text-white">Drilling Equipment</h4>
                                </Link>
                            </div>
                            <div className="col-sm-6 col-lg-3 mt-4 mt-sm-0 text-center">
                                <Link className="d-block" to="/Mud-pumps">
                                    <img className="w-100 rounded" src={Mp_Img} alt=""
                                         data-zanim-xs='{"delay":0.1,"animation":"zoom-in"}'
                                         data-zanim-trigger="scroll"/>
                                    <h4 className="text-white">Mud Pumps</h4>
                                </Link>
                            </div>
                            <div className="col-sm-6 col-lg-3 mt-4 mt-lg-0 text-center">
                                <Link className="d-block" to="/Well-control-equipment">
                                    <img className="w-100 rounded" src={Wce_Img} alt=""
                                         data-zanim-xs='{"delay":0.2,"animation":"zoom-in"}'
                                         data-zanim-trigger="scroll"/>
                                    <h4 className="text-white">Well Controll Equipment</h4>
                                </Link>
                            </div>
                            <div className="col-sm-6 col-lg-3 mt-4 mt-lg-0 text-center">
                                <Link className="d-block" to="/Handling-tools">
                                    <img className="w-100 rounded" src={Ht_Img} alt=""
                                         data-zanim-xs='{"delay":0.3,"animation":"zoom-in"}'
                                         data-zanim-trigger="scroll"/>
                                    <h4 className="text-white">Handling Tools</h4>
                                </Link>
                            </div>
                            <div className="col-sm-6 col-lg-3 mt-4 mt-lg-0 text-center">
                                <Link className="d-block" to="/Mud-system-and-solid-control-equipment">
                                    <img className="w-100 rounded" src={Mssce_Img} alt=""
                                         data-zanim-xs='{"delay":0.3,"animation":"zoom-in"}'
                                         data-zanim-trigger="scroll"/>
                                    <h4 className="text-white">Mud System & Solid Control Equipment</h4>
                                </Link>
                            </div>
                            <div className="col-sm-6 col-lg-3 mt-4 mt-lg-0 text-center">
                                <Link className="d-block" to="/Downhole-tools">
                                    <img className="w-100 rounded" src={Dt_Img} alt=""
                                         data-zanim-xs='{"delay":0.3,"animation":"zoom-in"}'
                                         data-zanim-trigger="scroll"/>
                                    <h4 className="text-white">Downhole Tools</h4>
                                </Link>
                            </div>
                            <div className="col-sm-6 col-lg-3 mt-4 mt-lg-0 text-center">
                                <Link className="d-block" to="/Drill-string-and-tubulars">
                                    <img className="w-100 rounded" src={Dst_Img} alt=""
                                         data-zanim-xs='{"delay":0.3,"animation":"zoom-in"}'
                                         data-zanim-trigger="scroll"/>
                                    <h4 className="text-white">Drill String & Tubulars</h4>
                                </Link>
                            </div>
                            <div className="col-sm-6 col-lg-3 mt-4 mt-lg-0 text-center">
                                <Link className="d-block" to="/Valves">
                                    <img className="w-100 rounded" src={V_Img} alt=""
                                         data-zanim-xs='{"delay":0.3,"animation":"zoom-in"}'
                                         data-zanim-trigger="scroll"/>
                                    <h4 className="text-white">Valves</h4>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                <We/>
                <Contact/>
                <Footer/>
                <LoadScriptTheme/>
            </div>
        );
    }
}

export default Home;