import React, { Component } from 'react'
var postscribe = require('postscribe');
var loadjquery = false;
export default class load_scripts extends Component {
    componentDidMount(){
        if(loadjquery===false){
            postscribe('#divscript', '<script src="https://code.jquery.com/jquery-3.5.1.min.js"></script>')
            loadjquery=true
        }
        postscribe('#divscript', '<script src="/assets/js/popper.min.js"></script>')
        postscribe('#divscript', '<script src="/assets/js/bootstrap.js"></script>')
        postscribe('#divscript', '<script src="/assets/lib/jquery.mb.ytplayer/jquery.mb.YTPlayer.min.js"></script>')
        postscribe('#divscript', '<script src="/assets/js/plugins.js"></script>')
        postscribe('#divscript', '<script src="/assets/lib/loaders.css/loaders.css.js"></script>')
        postscribe('#divscript', '<script src="/assets/js/stickyfill.min.js"></script>')
        postscribe('#divscript', '<script src="/assets/lib/remodal/remodal.js"></script>')
        postscribe('#divscript', '<script src="/assets/lib/prismjs/prism.js"></script>')
        postscribe('#divscript', '<script src="/assets/lib/sticky-kit/sticky-kit.min.js"></script>')
        postscribe('#divscript', '<script src="/assets/js/rellax.min.js"></script>')
        postscribe('#divscript', '<script src="https://cdn.polyfill.io/v2/polyfill.min.js?features=es6,Array.prototype.includes,CustomEvent,Object.entries,Object.values,URL"></script>')
        postscribe('#divscript', '<script src="/assets/lib/owl.carousel/owl.carousel.js"></script>')
        postscribe('#divscript', '<script src="/assets/lib/lightbox2/js/lightbox.js"></script>')
        postscribe('#divscript', '<script src="/assets/js/progressbar.min.js"></script>')
        postscribe('#divscript', '<script src="/assets/js/jquery.countdown.min.js"></script>')
        postscribe('#divscript', '<script src="/assets/lib/plyr/plyr.min.js"></script>')
        postscribe('#divscript', '<script src="/assets/js/typed.js"></script>')
        postscribe('#divscript', '<script src="/assets/js/theme.js"></script>')
    }
    render() {
        return (
            <div id="divscript"/>
        )
    }
}
