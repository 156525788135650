import React, {Component} from 'react';
import Iconstart from "../assets/img/icon-startup.svg";
import IconTarget from "../assets/img/icon-target.svg";
import IconTeam from "../assets/img/icon-teamwork.svg";
import Gmh_Img from "../assets/img/gmh.png";
import Global_Img from "../assets/img/global.png";

class We extends Component {
    render() {
        return (
            <>
                <section className="py-0">
                    <div className="container-fluid bg-200">
                        <div className="row align-items-center py-6 py-md-8 py-lg-0">
                            <div className="col-lg-4 px-lg-5">
                                <div className="media text-sans-serif overflow-hidden"
                                     data-zanim-timeline='{"delay":0.1}' data-zanim-trigger="scroll">
                                    <img className="mr-3" src={Iconstart} alt="icon" width="50"
                                         data-zanim-xs='{"delay":0.2,"animation":"slide-right"}'/>
                                    <div className="media-body">
                                        <div className="overflow-hidden">
                                            <h4 data-zanim-xs='{"delay":0.1,"animation":"slide-right"}'>Misión</h4>
                                        </div>
                                        <div className="overflow-hidden">
                                            <p className="mb-0" data-zanim-xs='{"delay":0.2,"animation":"slide-right"}'>
                                                El sector petrolero representa una de las industrias mas exigentes en el
                                                mercado, es así como para el suministro de equipos, repuestos,
                                                suministros, insumos, servicios; se debe contar con los mas altos
                                                estándares de calidad, eficiencia, eficacia, profesionalismo y
                                                compromiso, siempre en busca de satisfacer al 100 % las necesidades de
                                                nuestros clientes en las áreas de producción petrolera , Workover y
                                                perforación con cobertura nacional.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 px-lg-5 border-lg-left border-lg-right border-300 py-4 py-md-7">
                                <div className="media text-sans-serif overflow-hidden"
                                     data-zanim-timeline='{"delay":0.2}' data-zanim-trigger="scroll">
                                    <img className="mr-3" src={IconTarget} alt="icon" width="50"
                                         data-zanim-xs='{"delay":0.2,"animation":"slide-right"}'/>
                                    <div className="media-body">
                                        <div className="overflow-hidden">
                                            <h4 data-zanim-xs='{"delay":0.1,"animation":"slide-right"}'>Visión</h4>
                                        </div>
                                        <div className="overflow-hidden">
                                            <p className="mb-0" data-zanim-xs='{"delay":0.2,"animation":"slide-right"}'>
                                                Somos una empresa en busca de posicionarnos a nivel nacional e
                                                internacional como la mejor opción de alianza estratégica en el sector
                                                petrolero, garantizando siempre nuestros estándares de calidad;
                                                comprometidos con nuestros clientes y respaldados con un factor humano
                                                profesional e idóneo. Estamos comprometidos en ser la empresa mas
                                                pujante en el sector de servicios, ambiente, calidad y seguridad.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 px-lg-5">
                                <div className="media text-sans-serif overflow-hidden"
                                     data-zanim-timeline='{"delay":0.3}' data-zanim-trigger="scroll">
                                    <img className="mr-3" src={IconTeam} alt="icon" width="50"
                                         data-zanim-xs='{"delay":0.2,"animation":"slide-right"}'/>
                                    <div className="media-body">
                                        <div className="overflow-hidden">
                                            <h4 data-zanim-xs='{"delay":0.1,"animation":"slide-right"}'>Nuestra
                                                empresa</h4>
                                        </div>
                                        <div className="overflow-hidden">
                                            <p className="mb-0" data-zanim-xs='{"delay":0.2,"animation":"slide-right"}'>
                                                D&S SOLUCIONES EMPRESARIALES GLOBAL S.A.S, está localizada en Mosquera ,
                                                Cundinamarca y desde sus comienzos se ha especializado en proveer
                                                equipos y repuestos para el mercado petrolero latinoamericano.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="text-center py-1">
                    <div className="container-fluid bg-holder">
                        <div className="justify-content-center align-items-center">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className=" overflow-hidden" data-zanim-xs='{"delay":0.1,"animation":"zoom-in"}'
                                         data-zanim-trigger="scroll">
                                        <div className="player " data-plyr-provider="youtube" data-plyr-embed-id="RavO7CyOaNA"/>
                                    </div>
                                </div>
                                <div className="col-sm-6">

                                    <div className="row justify-content-center">
                                        <div className="col-lg-7">
                                            <h1 className="mb-2 text-primary">Alianzas</h1>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center mt-0">
                                        <div
                                            className="col-6 col-md-4 col-lg-3 d-flex justify-content-center align-items-center">
                                            <div className="bg-light p-4 p-sm-6 h-100 rounded">
                                                <a  rel="noreferrer" href="http://gmh.platinoweb.com" >
                                                    <img
                                                    className="logo-grid" src={Gmh_Img} alt=""
                                                    data-zanim-xs='{"delay":0.1,"animation":"zoom-in"}'
                                                    data-zanim-trigger="scroll"/></a>
                                            </div>
                                        </div>
                                        <div
                                            className="col-6 col-md-4 col-lg-3 d-flex justify-content-center align-items-center">
                                            <div className="bg-light p-4 p-sm-6 h-100 rounded">
                                                <a href="https://globalstsas.com" target="_blank"><img
                                                    className="logo-grid" src={Global_Img} alt=""
                                                    data-zanim-xs='{"delay":0.2,"animation":"zoom-in"}'
                                                    data-zanim-trigger="scroll"/></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="text-center py-0 overflow-hidden bg-primary">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="position-relative">
                                    <iframe className="iframeprin" frameBorder="0"
                                            src="Catalogos/DandS/mobile/index.html"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default We;