import React, {Component} from 'react';
import {Link} from "react-router-dom";

class SectionTop extends Component {
    render() {
        return (
            <section className="py-0" id="default-cta-1">
                <div className="container-fluid">
                    <div className="position-absolute overflow-hidden a-0">
                        <div className="bg-holder overlay rounded HomeBackground"
                             data-zanim-lg='{"animation:":"zoom-out","delay":0}'>
                        </div>
                    </div>
                    <div className="row justify-content-center text-center py-7">
                        <div className="col-lg-9 px-4">
                            <h3 className="fs-2 fs-sm-3 text-white pt-5">Empresa</h3>
                            <Link className="btn btn-outline-light mt-4 btn-sm border-2x"
                               to="/contact">Contáctenos</Link>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default SectionTop;